







































import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'OleMissL2PreLabSDSPotassiumPermanganate',
  components: {ChemicalNotationInput, STextarea, AiLoadingOverlay, CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part2_1: null,
        part2_2: null,
        part2_3: null,
        part2_5: null,
        part2_6: null,
        part2_7: null,
        part2_8: null,
        part2_9: null,
        part2_10: null,
        part2_11: null,
        part2_12: null,
        part2_13: null,
      },
      questions: [
        {
          text: 'a) What is the chemical formula?',
          inputName: 'part2_1',
          units: null,
          formula: true,
        },
        {
          text: 'b) What is the CAS-Registry number?',
          inputName: 'part2_2',
          units: null,
          formula: null,
        },
        {
          text: 'c) What is the molecular mass?',
          inputName: 'part2_3',
          units: 'g/mol',
          formula: null,
        },
        {text: 'd) What is the melting point?', inputName: 'part2_5', units: '°C', formula: null},
        {text: 'e) Provide one (1) synonym.', inputName: 'part2_6', units: null, formula: null},
        {text: 'f) Provide one (1) use.', inputName: 'part2_7', units: null, formula: null},
        {
          text: 'g) What is the correct method for extinguishing a fire involving this chemical?',
          inputName: 'part2_8',
          units: null,
          formula: null,
        },
        {
          text: 'h) What are the safety hazards associated with using this chemical?',
          inputName: 'part2_9',
          units: null,
          formula: null,
        },
        {
          text: 'i) What are the safety precautions necessary to minimize the above hazards? Provide at least two.',
          inputName: 'part2_10',
          units: null,
          formula: null,
        },
        {
          text: 'j) What is the proper method for disposal for the chemical (from the SDS)? One method is sufficient.',
          inputName: 'part2_11',
          units: null,
          formula: null,
        },
        {
          text: 'k) When was the chemical discovered and who discovered it?',
          inputName: 'part2_12',
          units: null,
          formula: null,
        },
        {
          text: 'l) Write all the sources for the information you gathered about the chemical.',
          inputName: 'part2_13',
          units: null,
          formula: null,
        },
      ],
    };
  },
};
